import { FC, ReactNode, useState, KeyboardEventHandler, useEffect } from 'react'
import { FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ISelectOption } from '~/core/@types/global'
import { FormControlItem } from '~/core/ui/FormControlItem'
import If from '~/core/ui/If'
import { InputChips } from '~/core/ui/InputChips'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import { IEmailForm } from './EmailContentEditor'

const DropDownSelectEmailControl: FC<{
  onChange: (newValue: ISelectOption | ISelectOption[]) => void
  value?: ISelectOption | ISelectOption[]
  label: string
  autoFocus?: boolean
  rightAction?: ReactNode
  className?: string
  disabled?: boolean
  formState: FormState<IEmailForm>
  fieldName: 'to' | 'cc' | 'bcc'
  placeholder?: string
  options?: ISelectOption[]
  searchBy?: Array<string>
  disableSearch?: boolean
}> = ({
  formState,
  label,
  autoFocus = false,
  rightAction,
  value,
  className,
  onChange,
  disabled = false,
  fieldName,
  placeholder,
  options = [],
  searchBy = [],
  disableSearch
}) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')
  const [emails, setEmails] = useState<ISelectOption[]>([])

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (disableSearch) {
      if (!inputValue) return
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setEmails((prev) => [
            ...(prev || []),
            {
              value: inputValue,
              supportingObj: {
                name: inputValue
              }
            }
          ])
          setInputValue('')
          event.preventDefault()
      }
    }
  }

  useEffect(() => {
    setEmails(value as ISelectOption[])
  }, [value])

  return (
    <div className={className}>
      <div className="w-[60px] flex-none text-sm text-gray-500">{label}</div>
      <div className={`-mt-[2px] flex-1`}>
        <FormControlItem>
          {disabled ? (
            <div className="align-center flex flex-row flex-wrap px-[7px]">
              {((value as ISelectOption[]) || []).map((e) => (
                <div key={e.value} className="mr-2 mt-2 line-clamp-1">
                  <InputChips
                    isDragged={false}
                    label={e?.supportingObj?.name}
                    type="default"
                    size="sm"
                  />
                </div>
              ))}
            </div>
          ) : (
            <MultipleSelect
              autoFocus={autoFocus}
              variant="ghost"
              isDisabled={disabled}
              classNameOverride={{
                bordered: 'none',
                control: '!border-transparent !shadow-none !min-h-[20px]',
                valueContainer: 'py-0',
                loadingMessage: `${t('label:loading')}`,
                noOptionsMessage: `${t('label:noOptions')}`
              }}
              configSelectOption={{
                option: 'checkbox',
                supportingText: ['name', 'description'],
                avatar: true
              }}
              showClearIndicator={false}
              showDropdownIndicator={false}
              placeholder={placeholder || ''}
              creatable
              isClearable
              onChange={(value) => onChange(value as ISelectOption[])}
              options={options}
              size="sm"
              unstyled
              value={emails}
              destructive={formState.errors && !!formState.errors[fieldName]}
              onKeyDown={handleKeyDown}
              onInputChange={(newValue) => setInputValue(newValue)}
              inputValue={inputValue}
              formatCreateLabel={(val) => {
                return val
              }}
              searchBy={searchBy}
              disableSearch={disableSearch}
            />
          )}
        </FormControlItem>
      </div>
      <If condition={rightAction}>{rightAction}</If>
    </div>
  )
}

export default DropDownSelectEmailControl
